<template>
  <div class="Site">
    <SideBar :index="1" :avatar="UserInfo.header.includes('head_')?`./static/head/${UserInfo.header}`:UserInfo.header"></SideBar>
<!--    <van-nav-bar
        fixed
        :border="false" @click-right="onClickHall"
        :title="$t('hall.default[0]')"
    ><van-icon name="./skin/task01.png" slot="right" />
    </van-nav-bar>-->
<!--    <div class="info flex_ac">
      <img class="head" :src="`./static/head/${UserInfo.header}`" />
      <div>
        <div class="userName">{{ UserInfo.username.substring(0,15) }}</div>
        <div class="flex_ac">
          <div class="vip">VIP{{ UserInfo.vip_level }}</div>
          <div class="xx"></div>
        </div>
      </div>
      &lt;!&ndash; {{ $t("user.default[2]") }}:{{ UserInfo.idcode }} &ndash;&gt;
    </div>-->

    <div class="ScrollBox">
      <div class="box-card">
        <div class="border-grey">
          <div>
            <div style="font-size: 13px;">{{ $t("hall.default[1]") }}</div>
            <div  style="font-size: 13px; font-weight: bold; line-height: 23px;">{{ parseFloat(UserInfo.balance).toFixed(2)}} USDT</div>
          </div>
        </div>
        <div class="border-grey">
          <div>
            <div style="font-size: 9px;" @click="$router.push(`/user/userTaskRecord/${userInfo.uid}`)">
              <img src="~@/assets/img/hall/19468.png"/>
              </div>
            <div  style="font-size: 13px; line-height: 28px;">{{$t('hall2[3]')}}<!--My order--></div>
          </div>
        </div>
        <div class="border-grey">
          <div @click="$router.push('/myTask')">
            <div style="font-size: 9px;">
              <img src="~@/assets/img/hall/11790.png"/>
            </div>
            <div  style="font-size: 11px; line-height: 28px;">{{$t('hall2[4]')}}<!--Order List--></div>
          </div>
        </div>
        <div class="border-grey">
          <div>
            <div style="font-size: 9px;">
              <img src="~@/assets/img/hall/11791.png"/>
            </div>
            <div  style="font-size: 13px; line-height: 28px;">{{$t('hall2[5]')}}<!--Pending orders--></div>
          </div>
        </div>
        <div class="border-grey">
          <div @click="gotoBuy">
            <div style="font-size: 9px;">
              <img src="~@/assets/img/hall/19469.png"/>
            </div>
            <div  style="font-size: 13px; line-height: 28px;" >{{$t('hall2[6]')}}<!--buy--></div>
          </div>
        </div>
        <div class="border-grey">
          <div @click="$router.push('/sell')">
            <div style="font-size: 9px;">
              <img src="~@/assets/img/hall/19470.png"/>
            </div>
            <div  style="font-size: 13px; line-height: 28px;">{{$t('hall2[7]')}}<!--sell--></div>
          </div>
        </div>
      </div>
<!--      <div class="tool">
        <table style="text-align: left;width: 95%;line-height: 25px">
          <tr style="color:var(&#45;&#45;font_subtitle);font-size:16px"><td> </td></tr>
          <tr style="font-style: italic;font-size:24px;font-weight:600"><td> </td></tr>
          <tr style="color:#888"><td> </td></tr>
          <tr><td> <span style="font-style: italic;font-size: 14px">{{ $t("hall.default[8]") }}: {{ UserInfo.remains_numbers }} USDT </span></td></tr>
        </table>

        <div class="btn_sss flex_sb" @click="gotoBuy">
          <div class="btn_sss1" @click="gotoBuy">{{$t('hall.default[2]')}}</div>
          <div class="btn_sss2" @click="$router.push('/sell')">{{$t('hall.default[3]')}}</div>
        </div>
      </div>-->

      <div class="message border-grey">
        <div style="display: flex; width: 10%"><img src="~@/assets/img/hall/14630522.png"/></div>
        <div style="width: 90%;">
          <van-swipe :autoplay="2000" :vertical="isVertical" v-if="robotList.length>0"
                     :show-indicators="false"
                     class="hallswipe">
            <div v-for="(item, index) in robotList" :key="index">
              <van-swipe-item>
                <!--<img class="robotheader" :src="item.header.includes('head_')?`./static/head/${item.header}`:item.header"/>-->
                <div style="font-size: 12px;" >
                  <!--{{item.username.substring(0,5)+"*****"+item.username.substring(10)}}-->
                  {{item.username.substring(0,5)+"*****"}}
                  {{$t('common5[0]')}}
                  <span style="color: #f0a903;">{{item.amount}} USDT</span>
                  {{item.time}} {{$t('hall3[2]')}}{{item.timedesc}} {{$t('common5[2]')}}
                </div>
              </van-swipe-item>
            </div>
          </van-swipe>
        </div>
      </div>


      <!--<div class="welfares-order-nav border-grey">
        <div style="font-size: 13px;">Welfares Order</div>
        <div><img src="~@/assets/img/hall/19471.png"/></div>
      </div>-->

      <div class="title01">
        <van-tabs
            :border="false"
            color="var(--tab-line-color)"
            title-active-color="var(--tabs-title-color)"
            title-inactive-color="var(--tabs-title-inactive-color)"
            z-index="99"
            :fixed="false"
            icon-size="80"
            v-model="active"
            @change="changeTab"
        >
          <van-tab :title="$t('buy3[3]')"      />
          <van-tab :title="$t('buy3[4]')"      />
        </van-tabs>
      </div>
      <div class="records">
        <van-list v-if="active==0"
            v-model="isLoad"
            :finished="isFinished"
            :finished-text="listData0.length ? $t('vanPull[0]') : $t('vanPull[1]')"
            @load="onLoad"
            :class="{ Empty: !listData0.length }"
        >
          <div v-for="(item,index) in listData0" :key="index">
            <div class="order-item border-grey" style="height: 274px;">
              <div style="display: flex; width: 100%">
                <div style="padding-left :20px; height: 128px; width: 70%">
                  <div style="display: flex; margin-top: 20px;">
                    <div><img style="border-radius: 50%;" :src="item.header.includes('head_')?`./static/head/${item.header}`:item.header" width="36" height="36"/></div>
                    <div>{{item.username.slice(0, 4) + '******' + item.username.slice(10)}}</div>
                    <div v-if="item.user_type==2" style="margin-left: 4px;"><img src="~@/assets/img/skinbitcoin/22854@1.png"/></div>
                  </div>
                  <div style="line-height: 22px; font-size: 8px;">Trader's earnings in the past 90 days</div>
                  <div style="line-height: 22px; font-size: 13px; font-weight: bold;">{{item.price_rate}}%</div>
                  <!--<div style="line-height: 17px; font-size: 10px; color:#6bb853;">+$11,000,00.00</div>-->
                </div>
                <div style="width: 30%; display: flex; flex-direction: column;align-items:center; padding-right: 20px;justify-content: flex-end;">
                  <!--<div style="display: flex; line-height: 34px; width: 77px; padding-top: 10px; padding-bottom: 10px;">
                    <div style="text-align:center; width: 35%; background: rgba(255,255,255,0.13); border-left: 1px solid  var(&#45;&#45;bc-text-color-3);border:1px solid var(&#45;&#45;bc-text-color-3); border-radius: 10px 0 0 10px; font-size: 7px;">{{item.counthh}}</div>
                    <div style="text-align:center; width: 25px; background: rgba(255,255,255,0.13); font-size: 7px; border-top:1px solid var(&#45;&#45;bc-text-color-3);border-bottom:1px solid var(&#45;&#45;bc-text-color-3);">{{item.countmm}}</div>
                    <div style="text-align:center; width: 35%; background: rgba(255,255,255,0.13); font-size: 7px;  border-radius: 0 10px 10px 0;border:1px solid var(&#45;&#45;bc-text-color-3);">{{item.countss}}</div>
                  </div>-->
                  <van-button style="height:26px; background: #f0a903;width: 77px; margin-bottom: 10px;"  @click="verifySubmitTask(2,item)">
                    <span style="font-size: 14px">{{ $t("hall.btn[0]") }}<!-- {{ $t("hall.default[10]") }}--></span>
                  </van-button>
                  <div style="height: 14px;"></div>
                  <!--<van-button style="height:26px; background: #6bb853;width: 77px;" to="/hall">
                    <span style="font-size: 10px">{{ $t("hall.btn[1]") }}</span>
                  </van-button>-->
                </div>
              </div>

              <div style="width: 90%;height: 2px; border-bottom: 1px solid  var(--bc-text-color-3);"></div>
              <div style="height: auto;font-size: 13px; width: 100%;">
                <div style="line-height: 27px; display: flex; ">
                  <div style="width: 70%;padding-left: 20px;">{{ $t("hall.list[1]") }}: <!--Unit Price--></div>
                  <div style="width: 30%; text-align: end; padding-right: 20px;">{{parseFloat(item.price).toFixed(2)}}</div>
                </div>
                <div style="line-height: 27px; display: flex; ">
                  <div style="width: 50%;padding-left: 20px;">{{ $t("hall.list[0]") }}<!-- Quantity--></div>
                  <div style="width: 50%; text-align: end; padding-right: 20px;"> {{parseFloat(item.total_number).toFixed(2)}} USDT</div>
                </div>
                <div style="line-height: 27px; display: flex; ">
                  <div style="width: 50%;padding-left: 20px;"> {{ $t("hall.list[2]") }}<!--Not Transacted--></div>
                  <div style="width: 50%; text-align: end; padding-right: 20px;"> {{parseFloat(item.remains_number).toFixed(2)}} USDT</div>
                </div>
                <div style="line-height: 27px; display: flex; ">
                  <div style="width: 30%;padding-left: 20px;">{{ $t("hall.list[6]") }} <!--Quota--></div>
                  <div style="width: 70%; text-align: end; padding-right: 20px;"> {{parseFloat(item.min_number).toFixed(2)}}-{{parseFloat(item.max_number).toFixed(2)}} USDT</div>
                </div>
                <div v-if="UserInfo.id != item.target_uid" style="line-height: 27px; display: flex; ">
                  <div style="width: 50%;padding-left: 20px;">{{ $t("ID") }}<!--福利用户ID--></div>
                  <div style="width: 50%; text-align: end; padding-right: 20px;">{{item.target_uid}}</div>
                </div>
              </div>

            </div>
          </div>
        </van-list>
        <van-list v-if="active==1"
            v-model="isLoad"
            :finished="isFinished"
            :finished-text="listData1.length ? $t('vanPull[0]') : $t('vanPull[1]')"
            @load="onLoad"
            :class="{ Empty: !listData1.length }"
        >
          <div v-for="(item,index) in listData1" :key="index" v-if="item.countdesc&&item.countdesc.length>0">
              <div class="order-item border-grey">
                <div style="display: flex; width: 100%">
                  <div style="padding-left :20px; height: 128px; width: 70%">
                    <div style="display: flex; margin-top: 20px;">
                      <div><img  style="border-radius: 50%;"  :src="item.header.includes('head_')?`./static/head/${item.header}`:item.header" width="36" height="36"/></div>
                      <div>{{item.username.slice(0, 4) + '******' + item.username.slice(10)}}</div>
                      <div v-if="userInfo.user_type==2" style="margin-left: 4px;"><img src="~@/assets/img/skinbitcoin/wangg.png" style="width: 20px;"/></div>
                    </div>
                    <div style="line-height: 22px; font-size: 8px;">Trader's earnings in the past 90 days</div>
                    <div style="line-height: 22px; font-size: 13px; font-weight: bold;">{{item.price_rate}}%</div>
                    <div style="line-height: 17px; font-size: 10px; color:#6bb853;">+$11,000,00.00</div>
                  </div>
                  <div style="width: 30%; display: flex; flex-direction: column;align-items:center; padding-right: 20px;">
                    <div style="display: flex; line-height: 34px; width: 77px; padding-top: 10px; padding-bottom: 10px;">
                      <div style="text-align:center; width: 35%; background: rgba(255,255,255,0.13); border-left: 1px solid  var(--bc-text-color-3);border:1px solid var(--bc-text-color-3); border-radius: 10px 0 0 10px; font-size: 9px;">{{item.counthh}}</div>
                      <div style="text-align:center; width: 25px; background: rgba(255,255,255,0.13); font-size: 9px; border-top:1px solid var(--bc-text-color-3);border-bottom:1px solid var(--bc-text-color-3);">{{item.countmm}}</div>
                      <div style="text-align:center; width: 35%; background: rgba(255,255,255,0.13); font-size: 9px;  border-radius: 0 10px 10px 0;border:1px solid var(--bc-text-color-3);">{{item.countss}}</div>
                    </div>
                    <van-button style="height:26px; background: #f0a903;width: 77px;"  @click="verifySubmitTask(2,item)">
                      <span style="font-size: 14px">{{ $t("hall.btn[0]") }}</span>
                    </van-button>
                    <div style="height: 14px;"></div>
                   <!-- <van-button style="height:26px; background: #6bb853;width: 77px;" to="/hall">
                      <span style="font-size: 10px">{{ $t("hall.btn[1]") }}</span>
                    </van-button>-->
                  </div>
                </div>

                <div style="width: 90%;height: 2px; border-bottom: 1px solid  var(--bc-text-color-3);"></div>
                <div style="height: auto;font-size: 13px; width: 100%;">
                  <div style="line-height: 27px; display: flex; ">
                    <div style="width: 70%;padding-left: 20px;">{{ $t("hall.list[1]") }}: <!--Unit Price--></div>
                    <div style="width: 30%; text-align: end; padding-right: 20px;">{{parseFloat(item.price).toFixed(2)}}</div>
                  </div>
                  <div style="line-height: 27px; display: flex; ">
                    <div style="width: 50%;padding-left: 20px;">{{ $t("hall.list[0]") }}<!-- Quantity--></div>
                    <div style="width: 50%; text-align: end; padding-right: 20px;"> {{parseFloat(item.total_number).toFixed(2)}} USDT</div>
                  </div>
                  <div style="line-height: 27px; display: flex; ">
                    <div style="width: 50%;padding-left: 20px;"> {{ $t("hall.list[2]") }}<!--Not Transacted--></div>
                    <div style="width: 50%; text-align: end; padding-right: 20px;"> {{parseFloat(item.remains_number).toFixed(2)}} USDT</div>
                  </div>
                  <div style="line-height: 27px; display: flex; ">
                    <div style="width: 30%;padding-left: 20px;">{{ $t("hall.list[6]") }} <!--Quota--></div>
                    <div style="width: 70%; text-align: end; padding-right: 20px;"> {{parseFloat(item.min_number).toFixed(2)}}-{{parseFloat(item.max_number).toFixed(2)}} USDT</div>
                  </div>
                  <div style="line-height: 27px; display: flex; ">
                    <div style="width: 50%;padding-left: 20px;">{{$t('buy3[5]')}} <!--Number of people--></div>
                    <div style="width: 50%; text-align: end; padding-right: 20px;">{{item.count_limit}}</div>
                  </div>
                </div>

                <div style="width: 100%;display: flex; margin-top: 12px;">
                  <div style="width: 10%;"></div>
                  <div style="width: 90%; position:relative;">
                   <div style="float: right; padding-right: 20px;">
                     <div style="display: flex;">
                       <div v-for="(user,index) in item.users" :key="index" v-if="index<7" @click="goHmlist(item.id)">
                         <div style="position:relative;margin-left: -20px;">
                           <img class="robotheader" :src="user.header.includes('head_')?`./static/head/${user.header}`:user.header" @click="goHmlist(item.id)"/>
                         </div>
                         <!--<img v-if="item.users.length>0" class="robotheader" :src="`./skinlscc/plus.png`" @click="goHmlist(item.id)"/>-->
                       </div>
                       <!--
                       <div><img src="~@/assets/img/hall/1020.png"/></div>
                       <div style="position:relative;margin-left: -20px;"><img src="~@/assets/img/hall/1020.png"/></div>
                       <div style="position:relative;margin-left: -20px;"><img src="~@/assets/img/hall/1020.png"/></div>
                       -->
                     </div>
                   </div>
                  </div>
                </div>
              </div>
          </div>
        </van-list>
        <div style="height: 40px;"/>
      </div>
    </div>
<!--    <Footer />-->
    <van-dialog v-model="show_buy"  :title="$t('hall.list[7]')" @confirm="addNewOrder"
                :show-confirm-button="true"  :show-cancel-button="true" @cancel="cancelTask">
      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">
        <van-field class="border-grey"
            v-model="postData.amount"
            :placeholder="$t('hall.list[4]')" style="margin-left: 20px"
        />
        <div style="margin-left: 30px;margin-top: 10px">{{$t('hall.list[8]')}}::{{parseFloat(postData.amount*currItem.price).toFixed(2)}} {{usdtinfo.sign}}</div>
      </div>
    </van-dialog>
    <van-dialog v-model="show_sell"  :title="$t('hall.list[3]')" @confirm="addNewOrder"
                :confirmButtonText="$t('common[2]')" :cancelButtonText="$t('common[3]')"
                :show-confirm-button="true"  :show-cancel-button="true" @cancel="cancelTask">
      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">
        <van-field
            class="border-grey"
            v-model="postData.amount"
            :placeholder="$t('hall.list[4]')" style="margin-left: 20px"
        />
        <div style="margin-left: 30px;margin-top: 10px">{{$t('hall.list[5]')}}:{{parseFloat(postData.amount*currItem.price).toFixed(2)}} {{usdtinfo.sign}}</div>
      </div>
    </van-dialog>
    <van-dialog v-model="check_false"  :title="check_title" @confirm="confirmCheck"
                :confirmButtonText="checkConfirmText" :before-close="onBeforeClose"
                :show-confirm-button="true"  :show-cancel-button="false">
      <div style="text-align: center">
        <div v-if="check_false==1">
        </div>
        <div v-else-if="check_false==2">
          <div style="font-size: 16px;margin-bottom: 10px;margin-top: 10px">
            {{$t('common4[4]')}} {{parseFloat(deposit).toFixed(2)}} TRX
          </div>
        </div>
        <div v-else-if="check_false==3">
          <h4 v-if="busdeposit>0">
            {{$t('wallet.default[17]')}} : {{busdeposit}} USDT
          </h4>
          <h4 v-if="busmoney>0">
            {{$t('wallet.default[19]')}} : {{busmoney}} USDT
          </h4>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      infoData: '',
      deposit:0,
      checkConfirmText:this.$t('common[2]'),
      busmoney:0,
      busdeposit:0,
      isVertical:true,
      //check_false:0,
      check_false:false,
      check_title:null,
      show_sell:false,
      show_buy:false,
      coundowns:[[],[]],
      levelStr:'',
      postData:{task_id:0,amount:''},
      usdtamount:0,
      usdtinfo:null,
      currItem:{"price":0},
      robotList:[],
      selllist:[],
      listData0:[],
      listData1:[],
      listData: [[],[]],
      endTime:1614217890000,
      commonPrice:0,
      time:0,
      timer:null,
      isLoad: false,
      userInfo:{},
      isFinished: false,
      isRefresh: false,
      pageNo: 0,
      hallInfo:{islock:1,shimingauth:1},
      active:1,
    }
  },
  computed: {
  },
  watch: {

  },
  created() {
    this.commonPrice = this.InitData.USDTUSDT;

    this.$Model.GetUserInfo();
    let info = this.UserInfo;
    if (this.UserInfo.deposit_amount>0){
      this.deposit = parseFloat(this.UserInfo.deposit_amount).toFixed(2);
    }else{
      this.deposit = parseFloat(this.UserInfo.balance*this.UserInfo.deposit_rate).toFixed(2);
    }

    this.busmoney = parseFloat(this.UserInfo.bus_amount).toFixed(2);
    this.busdeposit = parseFloat(this.UserInfo.bus_deposit).toFixed(2);;
    // debugger
    // if (!info['shimingauth']||info['shimingauth']!=1){
    //   this.$router.push("/shimingauth");
    //   return;
    // }

    var that = this;

    //that.findTradeStatus();

    // this.timer = setInterval(() => {
    //   // console.log("get prices");
    //   that.findTradeStatus();
    // }, 2000);

    let param0 = {};
    this.$Model.GetRobotTasks(param0,(data) => {
      // debugger
      if (data['code']==1){
        this.robotList = data['info']
      }
    });

    this.usdtinfo = this.InitData.usdtinfo;

    this.levelStr = this.$t('hall4[1]');

    let param2 = {"task_type":2,status:1};
    this.$Model.GetHallTaskList(param2,(data) => {
      if (data['code']==1){
        this.selllist = data['info']
      }
    });

    this.$Model.GetUserInfo((data) => {
      if (data.code == 1) {
        this.uid = data.info.uid;
        this.userInfo = data.info;
        var ut = this.userInfo['user_type'];
        // this.levelStr = this.$t("busAuth3["+ut+"]")
        this.levelStr = "VIP"+this.userInfo.vip_level
      }
    });
  },
  mounted() {
    this.initCountdown();
  },
  activated() {

  },
  destroyed() {

  },
  beforeDestroy() {
    console.log("clear  hall timer");
    clearInterval(this.timer);
    if(this.$countdown) clearInterval(this.$countdown);
  },
  methods: {
    onClickHall(){
      this.$router.push("/user/userTaskRecord");
    },
    initCountdown(){
      var that = this;
      if(this.$countdown) clearInterval(this.$countdown);
      //定时器
      this.$countdown = setInterval(()=>{
        var items = that.listData1;
        var dayseconds = 60*60*24*1000;
        // that.coundowns[that.active] = [];
        for (var i=0;i<items.length;i++){
          var now = Date.parse(new Date());
          var timeout = items[i]['timeout'];
          var ttstr = "";
          var diffTime = parseInt(timeout - now)/1000;
          if (diffTime>0){
            var mS = 60;
            var hS = mS*60;
            var dS = 24*hS;
            var dd = parseInt(diffTime/dS);
            if (dd>0){
              // ttstr += dd+" D ";
              diffTime -= dd*dS;
            }
            var hh = parseInt((diffTime)/hS);
            var mm = parseInt((diffTime-hh*hS)/mS);
            var ss = diffTime-hh*hS-mm*mS;
            ttstr += hh+" H "+mm+" m "+ss+" s";
          }

          items[i]['countdesc'] = ttstr;
          items[i]['counthh'] = hh+"h";
          items[i]['countmm'] = mm+"m";
          items[i]['countss'] = ss+"s";
        }
        that.listData1 = items;
        // console.log('ddd');
      },1000);
    },
    onBeforeClose(action,done){
      return done(false)
    },
    confirmCheck(){
      if (this.check_false==1) {
        this.$router.push("/user/bindAccount");
      }else if (this.check_false==2){
        this.$router.push("/shimingauth");
      }else if (this.check_false==3){
        this.$router.push("/busauth");
      }else if (this.check_false==4){
        this.$router.go(-1);
      }
    },
    onLoad() {
      this.getListData("load");
    },
    findTradeStatus(){
      this.$Model.TradeStatus((data) => {
        // console.log("findTradeStatus")
        // return;
        if (data['code']==1){
          this.hallInfo = data['info'];
          let info = this.hallInfo;
          if (info['bindAccount']==1) {
            this.$Dialog.Toast(this.$t('common4[7]'));
            this.$router.push("/user/bindAccount");
          }else if (info['shimingauth']==1){
            // this.$Dialog.Toast(this.$t('common4[8]'));
            this.$router.push("/shimingauth");
          }else if (info['busauth']==1){
            // this.$Dialog.Toast(this.$t('busAuth2[0]'));
            this.$router.push("/busauth");
          }else if (info['islock']==2){
            this.$Dialog.Toast(this.$t('common5[5]'));
            this.$router.go(-1);
          }

          // if (info['bindAccount']==1) {
          //   this.check_title = this.$t('common4[7]');
          //   this.check_false = 1;
          //   this.checkConfirmText = this.$t('bindAccount.default[0]');
          // }else if (info['shimingauth']==1){
          //   this.check_title = this.$t('common4[8]');
          //   this.checkConfirmText = this.$t('common4[0]');
          //   this.check_false = 2;
          // }else if (info['busauth']==1){
          //   this.check_title = this.$t('busAuth2[0]');
          //   this.checkConfirmText = this.$t('busAuth2[3]');
          //   this.check_false = 3;
          // }else if (info['islock']==2){
          //   this.check_title = this.$t('common5[5]');
          //   this.check_false = 4;
          // }

        }
      });
    },
    changeTab(){
      // this.active = index;
      this.getListData("init");
    },
    onRefresh() {
      // this.getListData("init");
    },
    goHmlist(id) {
      this.$router.push(`/hmlist/${id}`);
    },
    getListData(type) {
      this.isLoad = true;
      this.isRefresh = false;
      if (type == "load") {
        this.pageNo += 1;
      } else {
        this.pageNo = 1;
        this.isFinished = false;
      }

      let param = {"ordertype":this.active,status:1,page_no: this.pageNo,page_size:200};

      this.$Model.GetHallTaskList(param,(data) => {
        this.isLoad = false;
        if (data['code']==1){
          data.info.forEach(item=>{
            var ctime = item.counttime;
            var countdesc = ''
            if (ctime==0){
              countdesc = this.$t('hall3[6]');
            }else{
              var countdesc = '';
              for (var i=0;i<ctime.length;i++){
                countdesc += ' '+ctime[i]+" "+this.$t('hall3['+i+']');
              }
            }
            item.countdesc = '';
            var price_rate = (item.price-this.usdtinfo.price)/this.usdtinfo.price*100
            // price_rate = 13;
            price_rate = parseFloat(price_rate).toFixed(2);
            if (price_rate>0){
              price_rate = "+"+price_rate;
            }
            item.price_rate = price_rate
            // item.countdownstr = 'EE';
          });
          // this.selllist = data['info']
          if (type == "load") {
            if (this.pageNo == 1) {
              if (this.active==0){
                this.listData0 = data.info;
              }else{
                this.listData1 = data.info;
              }
            } else {
              if (this.active==0){
                this.listData0 = this.listData0.concat(data.info);
              }else{
                this.listData1 = this.listData1.concat(data.info);
              }
            }
          } else {
            if (this.active==0){
              this.listData0 = data.info;
            }else{
              this.listData1 = data.info;
            }
          }
          if (this.pageNo == data.data_total_page) {
            this.isFinished = true;
          } else {
            this.isFinished = false;
          }
        } else {
          // this.listData[this.active] = [];
          this.isFinished = true;
        }
      });
    },
    onClickRight(){
      this.$router.push("/user/userTaskRecord");
    },
    verifySubmitTask(index,item){
      this.$Model.TradeStatus((data) => {
        if (data['code']==1){
          let info = data['info'];
          if (info['shimingauth']==1){
            this.$router.push("/shimingauth");
            return ;
          }
          this.gotoTask(index,item);
        }
      })
    },
    gotoTask(index,item){
      if (this.hallInfo['islock']==2){
        this.$Dialog.Toast(this.$t('common5[5]'));
        return;
      }
      this.currItem = item;
      if (index==1){
        this.show_buy = true;
        this.show_sell = false;
      }else{
        this.show_sell = true;
        this.show_buy = false;
      }
    },
    cancelTask(){
      this.postData.amount = '';
    },
    gotoWithdraw(){
      let info = this.userInfo;
      if (info.withdraw_body&&info.withdraw_body.length>0){
        this.msg['title'] = info.withdraw_title;
        this.msg['content'] = info.withdraw_body;
        this.showMsg = true;
        return;
      }
      this.$router.push('/user/withdraw');
    },
    addNewOrder() {
      let amount = parseFloat(this.postData['amount']);
      let item = this.currItem;
      let hallstatus = this.hallInfo;
      if (amount<=0){
        this.$toast(this.$t('hall.kbip[2]'));
        this.postData.amount = '';
        return;
      }else if (amount>item.remains_number){
        this.$toast(this.$t('hall.kbip[1]'));
        this.postData.amount = '';
        return;
      }else if (item.max_number>0&&(amount>item.max_number)){
        this.postData.amount = '';
        this.$toast(this.$t('hall.kbip[2]'));
        return;
      }else if (amount>item.remains_number){

      }

      this.postData['task_type'] = 2;
      this.postData['task_id'] = item.id;
      this.postData['order_number'] = item['order_number'];
      this.$Model.SubmitTaskOrder(
          this.postData,
          (data) => {
            let msg = '';
            switch (data.code){
              case 1:
                msg = this.$t('common3[0]');
                break;
              case -1:
                msg = this.$t('hall.kbip[2]');
                break;
              case -2:
                msg = this.$t('hall[2]');
                break;
              case -3:
                msg = this.$t('taskOrder2[1]');
                break;
              case -4:
                msg = this.$t('common4[7]');
                break
              case -5:
                msg = this.$t('common4[8]');
                break
              default:
                msg = data.code_dec;
            }
            let duration = 2000;
            if (data.wait){
              duration = data.wait*1000
            }
            let msgg = {
              message: msg,
              duration: duration
            }
            this.$toast(msgg);
            if (data.code==1){
              this.$router.push("/myTask");
            }
          }
      );
      this.postData.amount = '';
      console.log("xxxxxxxxx")

    },
    gotoBuy(){
      let usertype = this.UserInfo.user_type;
      if (usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        return;
      }else{
        this.$router.push("/buy");
      }
    }
  }
}
</script>
<style scoped  lang="scss">
.PageBox {

}

.van-nav-bar {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background: var(--main-bg-color);
}
.van-nav-bar::v-deep .van-nav-bar__title{
}

.robotheader{
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 32px;
  height: 32px;
}

.href {
  color: #AD890C;
}

.records {
  width: 92%;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}
.Content::v-deep img{
  max-width: 100%;
}
.van-cell__title{
  font-size: 14px;
}

.ScrollBox{

}

.itemDesc{
  border:2px solid #5652FD;
  width: 36px;
  height: 36px;
  font-size: 12px;
  margin-left: 120px;
  text-align: center;
  background-color: #24303C;
  color: #fff;
  border-radius: 50%;
}

.PageBox ::v-deep  .van-grid-item__content {
  padding: 10px !important;
  display: flex;
  background-color:#0000;
}

.PageBox ::v-deep  .van-grid-item__text{
  color: #cccccc;
}


.title00 {
  background: #FDEAD9;
  width: 95%;
  color: #000;
  margin: auto;
  font-weight: bold;
  line-height: 40px;
  text-align: center
}
.MyHeader {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 82px;
  height: 82px;
}
.MyHeader img {
  width: 100%;
}

.title01 {
// background: #191C23;
  width: 95%;
  margin: auto;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  margin-bottom: 10px;
  .btn_active{
    font-size: 16px;
    position: relative;
    &::after{
      position: absolute;
      content:'';
      width: 60px;
      left: calc(50% - 30px);
      bottom: 5px;
      height: 5px;
      border-radius: 2.5px;
      background: #3FC3D6;
    }
  }
  .btn0{
    font-size: 16px;
    color: #666;
  }
}

.sellitem {
  color: var(--cell_title);
  text-align: left;
  font-size: 15px;
  padding: 10px 10px;
  background-color: var(--panel_color);
  border-radius: 10px;
  margin-bottom: 10px;
  .text_name{
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #f1f1f1;
  }
  .text_name1{
    height: 26px;
    line-height: 26px;
    font-weight: 600;
    span{
      font-weight: 400;
      width:100px;
      display: inline-block;
    }
  }
}

.PageBox::v-deep .tool::before {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -80%;
}
.tool {
  border-radius: 16px;
  background-color: var(--panel_color);
  overflow: hidden;
  z-index: 99;
  margin: 5px 3%;
  padding: 15px 20px;
}


.ScrollBox::v-deep .van-cell{
  padding:20px 5px;
  border-bottom: 1px solid #D9D9D9;
}
.ScrollBox::v-deep .van-cell:last-child{
  border-bottom: 0px solid #D9D9D9;
}
.ScrollBox::v-deep .van-cell::after{
  display: none;
}
.ScrollBox::v-deep .van-icon-chat-o::before{
  content: '';
  border-radius: 50%;
  background: #191C23;
  width:8px;
  height: 8px;
}
.ScrollBox::v-deep .van-icon-chat-o{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ScrollBox::v-deep .van-cell__title{
  font-size: 16px;
  font-family: ui-serif;
}
.ScrollBox::v-deep h3{
  font-size: 1.05rem;
  font-family: emoji;
}
.van-cell::v-deep .van-icon{
  font-size: 18px;
  color:#333;
}

.hallswipe {
  width: 96%;
  //margin-left: 6px;
  padding: 15px;
  height: 26px;
  z-index: 50;
  margin-top: -9px;
  //margin-bottom: 5px;
  color: var(--font_color);
  background:transparent;
}


.ScrollBox ::v-deep  .van-tab :nth-of-type(1) div {
  background-color: #000;
  padding: 5px;
  border: 1px solid;
  border-radius: 20px;
  color: #fff;
}

.ScrollBox ::v-deep  .van-tab--active :nth-of-type(1) div {
  background-color: #F5C73A;
  padding: 5px;
  border-radius: 20px;
  color: #000;
}
.ScrollBox ::v-deep  .van-tab--active :nth-of-type(1) div div {
  background-color: #F5C73A;
  padding: 5px;
  border-radius: 20px;
  color: #000;
}

.PageBox::v-deep .van-popup__close-icon--top-right{
  color:#333;
  top:20px;
  right: 20px;
}
.PageBox::v-deep .Content{
  margin-top:40px;
  padding:0 20px;
}
.PageBox::v-deep .Content p{
  font-size: 13px;
  line-height: 1.7;
  font-family: 'vant-icon';
  font-weight: 400;
  color: #666666;
}

.info{
  padding: 0px 30px 20px 30px;
  .head{
    height: 68px;
    width: 68px;
    border-radius: 34px;
    border: 2px solid #fff;
    margin-right: 10px;
  }
  .userName{
    font-size: 20px;
    color: #fff;
    margin-bottom: 6px;
  }
  .vip{
    background: url(~@/assets/img/new/vip.png) no-repeat;
    background-size: auto 100%;
    height: 20px;
    line-height: 20px;
    padding-left: 26px;
    color: #AB783F;
    font-size: 12px;
    font-weight: 600;
    width: 80px;
  }
  .xx{
    color: #f1f1f1;
  }
}

.btn_sss{
  margin-top: 10px;
  .btn_sss1{
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    width: calc(50% - 10px);
    background: #43407C;
    border: 2px solid #9183F1;
    text-align: center;
    font-size: 16px;
    color: #C0B7FF;
    font-weight: 600;
  }
  .btn_sss2{
    height: 52px;
    line-height: 52px;
    border-radius: 26px;
    width: calc(50% - 10px);
    background: linear-gradient(to bottom,#A496FC,#7B6CE3,#6858D7);
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
  }
}

.box-card {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 15px 20px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 113px;
    height: 77px;
    text-align: center;
  }
}

.message {
  height: 42px;
  margin: 5px 3%;
  padding: 8px 10px;
  display: flex;
}

.welfares-order-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  margin: 5px 3%;
  margin-top: 18px;
  padding: 15px 20px;

}

.order-item {
  height: 330px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ScrollBox ::v-deep  .van-tab__text {
  line-height: 20px !important;
}

</style>
